<template>
  <div class="tilt-sensor">
    <div
      class="data-page__title _flex _flex-justify__between _flex-align__center _font-weight__600 _font-size__heading3"
    >
      <span>Tilt Sensor</span>
      <el-button
        type="info"
        @click="handleDownCsv"
        :disabled="!sensorResult.rows || !sensorResult.rows.length"
        >Download CSV</el-button
      >
    </div>

    <el-form class="data-page__form-box" ref="form" :model="formData" inline label-position="left">
      <el-form-item label="Sensor">
        <el-select
          v-model="formData.sensor"
          clearable
          placeholder="All Sensor"
          @change="handleSearch"
        >
          <el-option v-for="item in sensorOptions" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="Date Range">
        <SelectDate
          type="daterange"
          v-model="formData.date"
          @change="handleSearch"
          :disabledDate="disabledDate"
        ></SelectDate>
      </el-form-item>
      <el-form-item label="Time Range">
        <SelectDate type="time" v-model="formData.time" @change="handleSearch"></SelectDate>
      </el-form-item>
    </el-form>

    <PowerFulTable
      :surplusHeight="48"
      :data="sensorResult.rows"
      :columns="columns"
      @sortCustom="handleSortCustom"
    />

    <el-pagination
      v-if="Number(sensorResult.total) !== 0"
      background
      layout="->, prev, pager, next"
      :total="Number(sensorResult.total)"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import { downloadImage } from '@/utils/batch-save'
import common from '@/common'
import PowerFulTable from '@/components/table/powerful-table'
import SelectDate from '@/components/select-datetime/select-date'
import { dataFindPage, tiltFind, findPageOutCSV } from '@/api/data'
import { parseTime } from '@/utils'
import { headers, formView } from '../index-data'

export default {
  components: { PowerFulTable, SelectDate },
  data() {
    return {
      // 表单区域数据
      formData: {
        sensor: '',
        // date: [new Date(), new Date()],
        date: '',
        // time: [parseTime(new Date(), '{y}-{m}-{d} 0:0:0'), new Date()],
        time: '',
        beginDateStr: '',
        endDateStr: '',
        beginTimeStr: '',
        endTimeStr: '',
        towerCode: common.code
      },
      formView,

      columns: headers,
      sensorOptions: [],
      sensorResult: {
        rows: [],
        total: 0
      },
      list: new Array(4).fill(0).map((item, index) => ({
        time: '10:00',
        tilt: '5.1',
        description: 'Angle over threshold about 3.1°',
        status: index % 2 === 0 ? '0' : '1'
      }))
    }
  },
  created() {
    tiltFind().then((res) => {
      this.sensorOptions = res.result
      const [value] = res.result
      this.formData.sensor = value
    })

    this.handleSearch()
  },
  activated() {
    this.getData()
  },
  methods: {
    disabledDate(time) {
      return this.$store.state.user.isOn
        ? !['2023-11-07', '2023-11-08'].includes(parseTime(time, '{y}-{m}-{d}'))
        : false
    },
    getData() {
      dataFindPage(this.formData).then((res) => {
        this.sensorResult = res.result
      })
    },
    handleCurrentChange(e) {
      this.formData.pageNo = e
      this.getData()
    },
    handleSearch() {
      const { date, time } = this.formData

      if (date && date.length) {
        const [start, end] = date
        this.formData.beginDateStr = parseTime(start, '{y}-{m}-{d}')
        this.formData.endDateStr = parseTime(end, '{y}-{m}-{d}')
      } else {
        this.formData.beginDateStr = ''
        this.formData.endDateStr = ''
      }

      if (time && time.length) {
        const [start, end] = time

        this.formData.beginTimeStr = parseTime(start, '{h}:{i}:{s}')
        this.formData.endTimeStr = parseTime(end, '{h}:{i}:{s}')
      } else {
        this.formData.beginTimeStr = ''
        this.formData.endTimeStr = ''
      }
      this.getData()
    },
    handleSortCustom({ prop, order }) {
      this.formData.orderBy = prop
      this.formData.sort = order ? order.replace('ending', '') : ''
      this.getData()
    },
    handleDownCsv() {
      const { beginTimeStr, endTimeStr, beginDateStr, endDateStr } = this.formData
      findPageOutCSV({
        beginTimeStr,
        endTimeStr,
        beginDateStr,
        endDateStr,
        towerCode: common.code
      }).then((res) => {
        downloadImage(res.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('../../../styles/cover.scss');
</style>
